<template>
  <HivebuyLoader v-if="loading" class="h-8 w-8" />
  <div v-else>
    <Multiselect
      v-if="departmentList.length === optionsList.length || optionsList.length"
      v-model="department"
      :can-clear="canClear"
      :filter-results="!shouldExecutePaginationApi"
      :hide-selected="hideSelected"
      :label="label"
      :loading="isApiLoading('departmentsDropDownList')"
      :mode="mode"
      :object="object"
      :options="filteredDepartments"
      :placeholder="placeholder"
      :searchable="true"
      :show-options="!isApiLoading('departmentsDropDownList')"
      :track-by="object ? undefined : trackBy"
      :value-prop="'id'"
      :close-on-select="closeOnSelect"
      class="w-full bg-white"
      name="department"
      @change="onDepartmentChange"
      @search-change="setSearch"
    >
      <template #option="{ option }">
        <span class="mr-4">
          <UserImage :user="option" />
        </span>
        <span class="truncate">
          {{ option.name }}
        </span>
      </template>
      <template #nooptions>
        <span class="text-sm text-gray-400 py-1 px-2">
          No search results 😔
        </span>
      </template>
      <template #singlelabel="{ value }">
        <div class="multiselect-single-label flex items-center">
          <OfficeBuildingIcon class="h-5 w-5 mr-1 text-primary flex-none" />
          <span class="truncate">{{ value.name }}</span>
        </div>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import { HivebuyLoader, UserImage } from "@/components/shared/index";
import { OfficeBuildingIcon } from "@heroicons/vue/outline";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_DEPARTMENT,
} from "@/store/CompanyManagement/types";
import Multiselect from "@vueform/multiselect";
import departmentsMixin from "@/components/mixins/departmentsMixin";
import { isObject } from "@/utils/utility_methods";

export default {
  name: "DepartmentSelect",
  components: { OfficeBuildingIcon, UserImage, Multiselect, HivebuyLoader },
  mixins: [departmentsMixin],
  props: {
    departmentList: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: [String, Array, Object],
      required: true,
    },
    mode: {
      type: String,
      default: "single",
    },
    label: {
      type: String,
      default: "label",
    },
    trackBy: {
      type: String,
      default: "id",
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    object: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select Department",
    },
    canClear: {
      type: Boolean,
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      loading: false,
      optionsList: [],
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["departmentsList"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["departments"]),
    department: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    filteredDepartments() {
      return this.optionsList.filter((dep) => {
        return (
          (dep.name.toLowerCase().includes(this.search) ||
            dep.costCenter?.toLowerCase().includes(this.search)) &&
          !dep.deleted
        );
      });
    },
  },
  watch: {
    departmentList: {
      async handler(newVal) {
        await this.updateOptionsList(newVal);
      },
      immediate: true,
      deep: true,
    },
    departments: {
      handler() {
        this.optionsList = this.departmentsList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getDepartment: GET_DEPARTMENT,
    }),
    onDepartmentChange(value, { name }) {
      this.$emit("change", value, { name });
    },
    setSearch(query) {
      this.executeDebounceSearch(this.onSearchUpdate, {
        key: "search",
        value: query.toLowerCase(),
      });
    },
    async updateOptionsList(val = []) {
      if (!this.loading) {
        this.optionsList = [];
        this.loading = true;
        const list = val.length
          ? val.slice()
          : this.departmentsList().map((department) => department.id);
        try {
          for (const department of list) {
            const depId = isObject(department) ? department.id : department;
            const departmentObj = await this.getDepartment(depId);
            this.optionsList.push(departmentObj);
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
:deep(.multiselect-caret) {
  z-index: 0;
}

:deep(.multiselect-clear) {
  z-index: 0;
}
</style>
