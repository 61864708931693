<template>
  <div v-if="!isInvoiceLoading">
    <div class="flex flex-col items-start p-4 m-4">
      <h3 class="text-lg text-primary font-semibold">
        {{ invoiceDetails?.invoiceNumber }}
      </h3>
      <p class="text-sm font-normal text-gray-500">
        {{ $t("invoice.uploadedAt") }}
        {{ formatDateTime(invoiceDetails.createdAt, language) }}
      </p>
    </div>
    <InvoiceDetails
      :invoice-details="invoiceDetails"
      :loading="loading"
      :is-detailed-view="true"
    />
  </div>
</template>

<script>
import { invoiceDetailsMixin } from "@/components/mixins";
import InvoiceDetails from "@/components/InvoiceApproval/InvoiceDetails.vue";

export default {
  name: "InvoiceDetailedView",
  components: {
    InvoiceDetails,
  },
  mixins: [invoiceDetailsMixin],
};
</script>
