import * as TYPES from "./types.js";
import { mappedPrData } from "@/utils/helpers/modelHelpers/purchaseRequest/requestDataMapper.js";
import { sortByDate } from "@/utils/helpers/dateHelper.js";
import {
  isObjectEmpty,
  updateOrCreate,
  addToArray,
} from "@/utils/utility_methods";
import { assignSupplierDetails } from "@/utils/helpers/modelHelpers/purchaseRequest/freeForm";
import store from "@/store";
import { AUTH_MODULE } from "@/store/Auth/types.js";
const isApprovalPending = (object) => {
  if (object?.approvals?.length && !object.instantApproval) return true;
  return false;
};
const isOutdated = (object, storeObject) => {
  if (Object.keys(storeObject).length < Object.keys(object).length)
    return false;

  if (storeObject.status == "P" && object.status == "PV") return true;
  if (
    !object.isItemLevelApprover &&
    isApprovalPending(object) &&
    !isApprovalPending(storeObject)
  )
    return true;
  if (object.isItemLevelApprover) {
    return object.items.some((item) => {
      const storeItem = storeObject.items.find(
        (storeItem) => storeItem.id == item.id
      );
      if (!storeItem) return false;
      if (isApprovalPending(item) && !isApprovalPending(storeItem)) return true;
    });
  }
  return false;
};
const calculateActionRequired = (object) => {
  const { actionRequired } = object;
  if (Array.isArray(actionRequired)) {
    const currentUser = store.getters[`${AUTH_MODULE}/user`];
    return actionRequired.some(
      (approval) => approval.email == currentUser.email
    );
  } else {
    return actionRequired;
  }
};
const setPrData = (state, payload) => {
  if (payload.activity == "created") {
    state.list = [payload.object.purchaseRequest.id, ...state.list];
  }

  const object = payload?.object?.purchaseRequest || payload;
  const { id, items, ...prObject } = object;
  prObject.actionRequired = calculateActionRequired(object);
  state.purchaseRequestDetails[id] = Object.assign(
    state.purchaseRequestDetails[id] || {},
    prObject,
    { id }
  );
  if (!items?.length) return;
  const itemsDetails = {};
  items.forEach((item) => {
    const previousItem = state.itemDetails[id]?.[item.id] || {};
    const newItem = Object.assign({}, item, {
      actionRequired: calculateActionRequired(item),
    });
    itemsDetails[item.id] = Object.assign(previousItem, newItem);
  });
  state.itemDetails[id] = Object.assign(
    state.itemDetails[id] || {},
    itemsDetails
  );
};
export default {
  [TYPES.MARK_ITEM_AS_ACTIONABLE]: (state, payload) => {
    const { action, position, isPreview } = payload;
    const index = state.currentRequest.items.findIndex(
      (item) => item.position == position
    );
    if (action == "delete") {
      let items = state.currentRequest.items.slice();

      items = items.filter((item, itemIndex) => itemIndex !== index);
      items = items.map((item, index) => {
        item.position = index + 1;
        item.updated = true;
        return item;
      });

      state.currentRequest = {
        ...state.currentRequest,
        items,
      };
      if (state.currentRequest.items.length == 0)
        state.currentRequest.currentPage = "items";
    } else if (action == "edit") {
      state.currentRequest.editItemPosition = position;
      state.currentRequest.currentPage = "items";
    }
    state.currentRequest.isPreview = isPreview;
  },
  [TYPES.SAVE_PR_ITEM]: (state, payload) => {
    let updatedItemPayload = Object.assign({}, payload);
    updatedItemPayload = assignSupplierDetails(updatedItemPayload);

    if (state.currentRequest.editItemPosition) {
      const index = state.currentRequest.items.findIndex(
        (item) => item.position == state.currentRequest.editItemPosition
      );
      updatedItemPayload.updated = true;
      state.currentRequest.items[index] = updatedItemPayload;
      state.currentRequest.editItemPosition = null;
    } else {
      state.currentRequest.items.push(updatedItemPayload);
    }
    if (state.currentRequest.items.length == 1) {
      state.currentRequest.title = state.currentRequest.items[0].name;
    }
    state.currentRequest.currentPage = "summary";
  },

  [TYPES.SET_CURRENT_PAGE]: (state, payload) => {
    state.currentRequest.currentPage = payload;
  },
  [TYPES.SET_CURRENT_REQUEST_DATA]: (state, payload) => {
    const { field, value } = payload;
    const redirectPage = payload.redirectPage;
    state.currentRequest[field] = value;
    if (redirectPage) state.currentRequest.currentPage = redirectPage;
  },
  [TYPES.RESET_CURRENT_REQUEST]: (state) => {
    state.currentRequest = {
      category: null,
      supplier: null,
      items: [],
      isPreview: true,
      itemLevelApproval: false,
      currentPage: "category",
      editItemPosition: null,
    };
  },
  [TYPES.SET_CURRENT_REQUEST_CATALOG_DATA]: (state, payload) => {
    state.newRequest = payload;
  },
  [TYPES.RESET_EDIT_POSITION]: (state) => {
    state.currentRequest.editItemPosition = null;
  },
  [TYPES.SET_REQUEST_DETAILS]: (state, payload) => {
    setPrData(state, payload);
  },
  [TYPES.SET_REQUEST_LIST_DETAILS]: (state, payload) => {
    payload.forEach((record) => {
      setPrData(state, record);
    });
  },
  [TYPES.SET_PURCHASE_REQUESTS_LIST]: (
    state,
    { payload, areFiltersUpdated }
  ) => {
    const payloadRecords = payload.map((record) => record.id);
    const records = areFiltersUpdated
      ? payloadRecords
      : [...state.list, ...payloadRecords];
    state.list = [...new Set(records)];
  },
  [TYPES.SET_LIST_FILTERS]: (state, payload) => {
    state.listFilters = payload;
  },
  [TYPES.SET_NEW_PURCHASE_REQUEST]: (state, { payload, edit }) => {
    const data = mappedPrData(payload);
    const requestPayload = {
      title: data.title,
      category: data?.category?.id,
      supplier: data?.supplier,
      items: data.items,
      currentPage: "summary",
    };
    if (edit) requestPayload.id = data.id;
    state.newRequest = requestPayload;
  },

  [TYPES.SET_CURRENT_REQUEST_ITEMS_ATTRIBUTE]: (state, { key, value }) => {
    if (!state.currentRequest?.items?.length) return;
    state.currentRequest.items.forEach((item) => (item[key] = value));
  },

  [TYPES.SET_PURCHASE_REQUESTS]: (state, payload) => {
    const payloadData = payload || [];
    const stateData = state.purchaseRequests || [];
    state.purchaseRequests = sortByDate(
      Object.values(
        [...stateData, ...payloadData].reduce((result, { id, ...rest }) => {
          result[id] = {
            ...(result[id] || {}),
            id,
            ...rest,
          };
          return result;
        }, {})
      )
    );
  },
  [TYPES.SET_DRAFT_PURCHASE_REQUEST_ITEM_IMAGE]: (state, payload) => {
    if (!isObjectEmpty(state.newRequest)) {
      const { id: itemId, images } = payload.object.item;
      const items = [...state.newRequest.items];
      const itemIndex = items.findIndex((item) => item.id == itemId);
      if (itemIndex >= 0) {
        items[itemIndex].images = images;
        state.newRequest = {
          ...state.newRequest,
          ...{ items },
        };
      }
    }
  },

  [TYPES.ADD_ITEM_TO_PR]: (state, payload) => {
    state.newPurchaseRequest.items = [
      ...state.newPurchaseRequest.items,
      payload,
    ];
  },
  [TYPES.ADD_TO_RECENTLY_VIEWED]: (state, id) => {
    const { recentlyViewed } = state;
    const filteredViewed = recentlyViewed.filter((item) => item !== id);
    state.recentlyViewed = [id, ...filteredViewed];
  },
  [TYPES.UPDATE_ITEM_IN_PR]: (state, payload) => {
    const purchaseRequestsItem = state.newPurchaseRequest.items;
    purchaseRequestsItem.splice(payload.index, 1, payload.item);
    state.newPurchaseRequest.items = purchaseRequestsItem;
  },

  [TYPES.SET_OPEN_CURRENT_REQUEST]: (state, payload) => {
    state.openCurrentRequest = payload;
  },
  [TYPES.ASSIGN_SHOPPING_LIST]: (state, payload) => {
    const { position, shoppingListDetails } = payload;
    const index = state.currentRequest.items.findIndex(
      (item) => item.position == position
    );
    state.currentRequest.items[index].shoppingItems = addToArray(
      state.currentRequest.items[index].shoppingItems,
      shoppingListDetails.id
    );
    state.currentRequest.shoppingLists = addToArray(
      state.currentRequest.shoppingLists,
      shoppingListDetails.shoppingList
    );
  },
  [TYPES.UNASSIGN_SHOPPING_LIST]: (state, payload) => {
    const { position, shoppingListDetails } = payload;
    const index = state.currentRequest.items.findIndex(
      (item) => item.position == position
    );
    state.currentRequest.items[index].shoppingItems =
      state.currentRequest.items[index].shoppingItems.filter(
        (item) => item !== shoppingListDetails.id
      );
  },

  [TYPES.SET_COMMENT]: (state, payload) => {
    const object = Object.keys(payload).includes("object")
      ? payload.object?.comment
      : payload;
    const objectToUpdate = Object.assign(
      {},
      state.purchaseRequestDetails[object.purchaseRequestId]
    );
    if (!objectToUpdate) return;
    objectToUpdate.comments = updateOrCreate(
      objectToUpdate.comments,
      object,
      true,
      false
    ).filter((comment) => comment.deleted == null && comment.comment);
    setPrData(state, objectToUpdate);
  },
  [TYPES.SET_DEFAULT_VALUES_FOR_REQUESTED_FOR]: (state, payload) => {
    state.requestedForValues[payload.id] = payload;
  },
  [TYPES.UPDATE_CURRENT_REQUEST_WITH_PREVIEW_DATA]: (state, payload) => {
    if (isOutdated(payload, state.currentRequest)) return;
    state.currentRequest = Object.assign(state.currentRequest, payload, {
      items: state.currentRequest.items.map((currentItem) => {
        const matchingPayloadItem = payload.items.find(
          (payloadItem) => payloadItem.position === currentItem.position
        );
        return {
          ...currentItem,
          ...matchingPayloadItem,
        };
      }),
    });
  },
  [TYPES.SET_DRAFT_LIST_PARAMS]: (state, payload) => {
    state.draftListParams = payload;
  },
  [TYPES.SET_DRAFT_LIST]: (state, payload) => {
    if (Array.isArray(payload)) {
      state.draftPurchaseRequests = payload;
    } else {
      state.draftPurchaseRequests = updateOrCreate(
        state.draftPurchaseRequests,
        payload
      );
    }
  },
  [TYPES.REMOVE_DRAFT_REQUEST]: (state, draftRequestId) => {
    state.draftPurchaseRequests = state.draftPurchaseRequests.filter(
      (request) => request.id !== draftRequestId
    );
  },
  [TYPES.SAVE_NEW_REQUEST_ATTRIBUTES]: (state, payload) => {
    const { item, index, action, title, object } = payload;
    const requestData = object || {
      title: "",
      category: null,
      supplier: null,
      items: [
        {
          name: "",
          quantity: 1,
          unit: "pc",
          recurrence: { interval: "" },
          netAmount: 0,
          grossAmount: 0,
          tax: 0,
          vat: 19,
        },
      ],
      itemLevelApproval: false,
    };
    if (action == "reset") {
      state.newRequest = requestData;
      return;
    }

    if (action == "delete") {
      state.newRequest.items.splice(index, 1);
      return;
    }
    if (state.newRequest.items[index]) {
      if (action == "overwrite") {
        state.newRequest.items[index] = item;
      } else {
        state.newRequest.items[index] = Object.assign(
          state.newRequest.items[index],
          item
        );
      }
    } else if (item) state.newRequest.items.push(item);
    if (title) {
      state.newRequest.title = title;
      return;
    }

    if (!title && !state.newRequest.title) {
      state.newRequest.title = state.newRequest.items[0].name;
    }
  },
};
