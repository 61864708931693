import { allowedRoles } from "@/utils/helpers/permissionHelper";
import NotAllowedPage from "@/components/shared/NotAllowedPage";
import Dashboard from "@/views/Dashboard";
import Notifications from "@/components/Notifications/DetailsView";
import { StepForm } from "@/components/PurchaseRequest/FreeForm/index.js";
import Catalogue from "@/components/Catalogue";
import ShowProfile from "@/components/Profile/ShowProfile";
import EditProfile from "@/components/Profile/EditProfile";
import Requests from "@/views/AllRequests";
import RequestDetail from "@/views/RequestDetail";
import Suppliers from "@/views/Suppliers";
import OrderList from "@/views/OrderList";
import Analytics from "@/components/Analytics/Analytics";
import Accounting from "@/views/Accounting";
import CompanyManagement from "@/views/CompanyManagement";
import ConnectedSuppliers from "@/components/CompanyManagement/ConnectedSuppliers/List";
import Settings from "@/components/CompanyManagement/Profile/Settings";
import Users from "@/components/CompanyManagement/Users";
import Departments from "@/components/CompanyManagement/Departments";
import Budgets from "@/components/CompanyManagement/Budgets";
import Categories from "@/components/CompanyManagement/Categories";
import CompanySuppliers from "@/components/CompanyManagement/CompanySuppliers/CompanySuppliers";
import Integrations from "@/components/CompanyManagement/Integrations";
import Webhooks from "@/components/CompanyManagement/Integrations/Webhooks.vue";
import ApprovalWorkflowListing from "@/components/CompanyManagement/ApprovalWorkflow/ApprovalWorkflowListing";
import { Editor } from "@/components/CompanyManagement/ApprovalWorkflow";
import DepartmentBudgetOverview from "@/components/Analytics/DepartmentBudgetOverview.vue";
import {
  InvoiceDashboard,
  InvoiceDecision,
} from "@/components/InvoiceApproval/index.js";
import Proposals from "@/views/Proposals";
import ProposalBoard from "@/components/Proposals/ProposalBoard/ProposalBoard";
import Projects from "@/views/Projects";
import ProjectView from "@/components/Projects/ProjectView";
import Contracts from "@/views/Contracts";
import ContractForm from "@/components/Contracts/ContractForm";
import ViewContract from "@/components/Contracts/ViewContract";
import ShoppingList from "@/views/ShoppingList";
import NewShoppingList from "@/components/ShoppingList/NewShoppingList";
import NotFound from "@/components/shared/NotFound.vue";
import PremiumFeature from "@/views/PremiumFeature";
import LegalTendencies from "@/components/CompanyManagement/LegalTendencies/List";
import SomethingWentWrong from "@/views/SomethingWentWrong";
import DetailedAnalytics from "@/components/Analytics/DetailedAnalytics";
import RequestCreation from "@/views/RequestCreation";
import RequestSummary from "@/components/RequestCreation/Summary.vue";
import { ApiKeyList } from "@/components/ApiKeys/index.js";
import News from "@/views/News.vue";
import DownloadList from "@/components/Downloads/list.vue";
import DraftList from "@/components/RequestCreation/List.vue";
import InvoiceDetail from "@/components/InvoiceApproval/InvoiceDetailedView.vue";

export default [
  {
    path: "/not-found",
    meta: { roles: allowedRoles("not-allowed") },
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/not-allowed",
    meta: { roles: allowedRoles("not-allowed") },
    name: "NotAllowed",
    component: NotAllowedPage,
  },
  {
    path: "/something-went-wrong",
    meta: { roles: allowedRoles("something-went-wrong") },
    name: "SomethingWentWrong",
    component: SomethingWentWrong,
  },
  {
    path: "/api-keys",
    meta: { roles: allowedRoles("api-keys") },
    name: "ApiKeyList",
    component: ApiKeyList,
  },
  {
    path: "/",
    meta: {
      roles: allowedRoles("dashboard"),
      title: "Hivebuy - Dashboard",
    },
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/downloads",
    meta: {
      roles: allowedRoles("downloads"),
      title: "Hivebuy - Downloads",
    },
    name: "Downloads",
    component: DownloadList,
  },
  {
    path: "/orderlist",
    meta: {
      roles: allowedRoles("order-list"),
      title: "Hivebuy - Order List",
    },
    name: "OrderList",
    component: OrderList,
  },
  {
    path: "/notifications",
    meta: { roles: allowedRoles("notifications") },
    name: "notifications",
    component: Notifications,
  },
  {
    path: "/request/new",
    meta: {
      roles: allowedRoles("new-request"),
      title: "Hivebuy - New Request",
    },
    name: "CreatePurchaseRequest",
    component: RequestCreation,
  },
  {
    path: "/request/edit/:id",
    meta: {
      roles: allowedRoles("new-request"),
      title: "Hivebuy - Edit Request",
    },
    name: "EditPurchaseRequest",
    component: RequestCreation,
  },
  {
    path: "/request/new/summary",
    meta: {
      roles: allowedRoles("new-request"),
      title: "Hivebuy - New Request Summary",
    },
    name: "purchaseRequestSummary",
    component: RequestSummary,
  },
  {
    path: "/purchase-request/:id?",
    meta: {
      roles: allowedRoles("purchase-request"),
      title: "Hivebuy - New Request",
    },
    name: "PurchaseRequest",
    component: StepForm,
  },
  {
    path: "/purchase-request/:id/edit",
    meta: {
      roles: allowedRoles("purchase-request"),
      title: "Hivebuy - Edit Request",
    },
    name: "PurchaseRequestEdit",
    component: StepForm,
  },
  {
    path: "/catalogue/:itemId?",
    meta: {
      roles: allowedRoles("catalogue"),
      title: "Hivebuy - Catalogue",
    },
    name: "Catalogue",
    component: Catalogue,
  },
  {
    path: "/proposals",
    meta: {
      roles: allowedRoles("proposals"),
      title: "Hivebuy - Proposals",
    },
    name: "Proposals",
    component: Proposals,
  },
  {
    path: "/proposals/:id",
    meta: {
      roles: allowedRoles("proposals"),
      title: "Hivebuy - Proposal Board",
    },
    name: "ProposalBoard",
    component: ProposalBoard,
  },
  {
    path: "/profile",
    meta: {
      roles: allowedRoles("profile"),
      title: "Hivebuy - Your Profile",
    },
    name: "UserProfile",
    component: ShowProfile,
  },
  {
    path: "/edit-profile",
    meta: {
      roles: allowedRoles("edit-profile"),
      title: "Hivebuy - Your Profile",
    },
    name: "EditProfile",
    component: EditProfile,
  },
  {
    path: "/projects",
    meta: {
      roles: allowedRoles("projects"),
      title: "Hivebuy - Projects",
    },
    name: "Projects",
    component: Projects,
  },
  {
    path: "/contracts/:id/edit",
    meta: {
      roles: allowedRoles("contracts"),
      title: "Hivebuy - Edit Contract",
    },
    name: "EditContract",
    component: ContractForm,
  },
  {
    path: "/contracts/:id",
    meta: {
      roles: allowedRoles("contracts"),
      title: "Hivebuy - View Contract",
    },
    name: "ViewContract",
    component: ViewContract,
  },
  {
    path: "/contracts/new",
    meta: {
      roles: allowedRoles("contracts"),
      title: "Hivebuy - New Contract",
    },
    name: "NewContract",
    component: ContractForm,
  },
  {
    path: "/contracts",
    meta: {
      roles: allowedRoles("contracts"),
      title: "Hivebuy - Contracts",
    },
    name: "Contracts",
    component: Contracts,
  },
  {
    path: "/project-view/:id?",
    meta: {
      roles: allowedRoles("project-view"),
      title: "Hivebuy - Project",
    },
    name: "ProjectView",
    component: ProjectView,
  },
  {
    path: "/requests",
    meta: {
      roles: allowedRoles("requests"),
      title: "Hivebuy - Purchase Requests",
    },
    name: "Requests",
    component: Requests,
  },
  {
    path: "/requests/:id",
    meta: {
      roles: allowedRoles("requests"),
      title: "Hivebuy - Single Request",
    },
    name: "RequestDetail",
    component: RequestDetail,
  },
  {
    path: "/new-request/suppliers",
    meta: {
      roles: allowedRoles("suppliers"),
    },
    name: "Suppliers",
    component: Suppliers,
  },
  {
    path: "/requests/draft",
    meta: {
      roles: allowedRoles("new-request"),
      title: "Hivebuy - Draft List",
    },
    name: "DraftList",
    component: DraftList,
  },
  {
    path: "/analytics",
    meta: {
      roles: allowedRoles("analytics"),
      title: "Hivebuy - Analytics",
    },
    name: "Analytics",
    component: Analytics,
    children: [
      {
        path: "departments",
        meta: {
          roles: allowedRoles("analytics-departments"),
          title: "Hivebuy - Department Budget Overview",
        },
        name: "DepartmentBudgetOverview",
        component: DepartmentBudgetOverview,
      },
      {
        path: "overview",
        meta: {
          roles: allowedRoles("analytics-basic-overview"),
          title: "Hivebuy - Analytics Overview",
        },
        name: "DetailedAnalytics",
        component: DetailedAnalytics,
      },
    ],
  },
  {
    path: "/orders/:type?",
    meta: {
      roles: allowedRoles("orders"),
      title: "Hivebuy - Purchase Orders",
    },
    name: "Accounting",
    component: Accounting,
  },
  {
    path: "/invoices/:id/",
    meta: {
      roles: allowedRoles("invoice-detail"),
      title: "Hivebuy - Invoice Detail",
    },
    name: "InvoiceDetail",
    component: InvoiceDetail,
  },
  {
    path: "/invoice-approval",
    meta: {
      roles: allowedRoles("invoice-approval"),
      title: "Hivebuy - Invoice Approval",
    },
    name: "InvoiceDashboard",
    component: InvoiceDashboard,
  },
  {
    path: "/invoice-decide/:invoiceId/",
    meta: {
      roles: allowedRoles("invoice-decide"),
      title: "Hivebuy - Invoice Approval",
    },
    name: "InvoiceDecision",
    component: InvoiceDecision,
  },
  {
    path: "/shopping-list",
    meta: {
      roles: allowedRoles("shopping-list"),
      title: "Hivebuy - Shopping List",
    },
    name: "ShoppingList",
    component: ShoppingList,
  },
  {
    path: "/shopping-list/new",
    meta: {
      roles: allowedRoles("shopping-list"),
      title: "Hivebuy - New Shopping List",
    },
    name: "NewShoppingList",
    component: NewShoppingList,
  },
  {
    path: "/company-management",
    meta: {
      roles: allowedRoles("company-management"),
      title: "Hivebuy - Company Management",
    },
    name: "CompanyManagement",
    component: CompanyManagement,
    children: [
      {
        path: "connected-suppliers",
        meta: {
          roles: allowedRoles("connected-suppliers"),
          title: "Hivebuy - Integrated Suppliers",
        },
        name: "ConnectedSuppliers",
        component: ConnectedSuppliers,
      },
      {
        path: "profile",
        meta: {
          roles: allowedRoles("company-profile"),
          title: "Hivebuy - Profile",
        },
        name: "Settings",
        component: Settings,
      },
      {
        path: "users",
        meta: { roles: allowedRoles("users"), title: "Hivebuy - Users" },
        name: "Users",
        component: Users,
      },
      {
        path: "departments",
        meta: {
          roles: allowedRoles("departments"),
          title: "Hivebuy - Departments",
        },
        name: "Departments",
        component: Departments,
      },
      {
        path: "legal-tendencies",
        meta: {
          roles: allowedRoles("legal-tendencies"),
          title: "Hivebuy - Legal Entities",
        },
        name: "LegalTendencies",
        component: LegalTendencies,
      },
      {
        path: "budgets",
        meta: { roles: allowedRoles("budgets"), title: "Hivebuy - Budgets" },
        name: "Budgets",
        component: Budgets,
      },
      {
        path: "categories",
        meta: {
          roles: allowedRoles("categories"),
          title: "Hivebuy - Categories",
        },
        name: "Categories",
        component: Categories,
      },
      {
        path: "company-suppliers",
        meta: {
          roles: allowedRoles("company-suppliers"),
          title: "Hivebuy - Suppliers",
        },
        name: "CompanySuppliers",
        component: CompanySuppliers,
      },
      {
        path: "integrations",
        meta: {
          roles: allowedRoles("integrations"),
          title: "Hivebuy - Integrations",
        },
        name: "Integrations",
        component: Integrations,
      },
      {
        path: "webhooks",
        meta: { roles: allowedRoles("webhooks") },
        name: "Webhooks",
        component: Webhooks,
      },
      {
        path: "approval-workflow",
        meta: { roles: allowedRoles("approval-workflow") },
        name: "Approval Workflow",
        component: ApprovalWorkflowListing,
      },
      {
        path: "approval-workflow-editor/:workflowId?",
        meta: {
          roles: allowedRoles("approval-workflow-editor"),
        },
        name: "Approval Workflow Editor",
        component: Editor,
      },
    ],
  },
  {
    path: "/premium-feature/:pageName",
    meta: {
      roles: allowedRoles("premium-feature"),
      title: "Hivebuy - Premium Feature",
    },
    name: "PremiumFeature",
    component: PremiumFeature,
  },
  {
    path: "/news",
    meta: {
      roles: allowedRoles("news"),
      title: "Hivebuy - News",
    },
    name: "News",
    component: News,
  },
];
