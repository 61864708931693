<template>
  <HivebuyLoader v-if="loading" class="h-36 w-36" />
  <div
    v-else
    :class="[
      'flex w-full gap-x-4 relative max-h-[70vh]',
      {
        'bg-white p-4 m-4 items-center justify-center ': isDetailedView,
        'overflow-y-scroll ': !isDetailedView,
      },
    ]"
  >
    <div class="flex grow-0 shrink-0 w-1/2">
      <div class="border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("global.invoiceNumber") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{ invoiceDetails.invoiceNumber }}
            </dd>
          </div>
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("global.title") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 max-w-[200px]"
            >
              {{ invoiceDetails.title }}
            </dd>
          </div>
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("global.pricing.amount") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              <CurrencyDisplay
                :value="invoiceDetails.totalNetAmount || 0"
                :locale="currencyLocale(invoiceDetails.totalNetAmountCurrency)"
              />
              /
              <CurrencyDisplay
                :value="invoiceDetails.totalGrossAmount || 0"
                :locale="
                  currencyLocale(invoiceDetails.totalGrossAmountCurrency)
                "
              />
            </dd>
          </div>
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("invoice.status.status") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
              <StatusTag
                type="invoice"
                :status="invoiceDetails.status"
                size="small"
              />
            </dd>
          </div>
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("invoice.table.invoiceDate") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{ formatDate(invoiceDetails.invoiceDate, true, language) }}
            </dd>
          </div>
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("invoice.table.dueDate") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{ formatDate(invoiceDetails.dueDate, true, language) }}
            </dd>
          </div>
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("invoice.table.serviceDate") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{ formatDate(invoiceDetails.serviceDate, true, language) }}
            </dd>
          </div>
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("invoice.table.customerName") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              {{ invoiceDetails.customerName || "-" }}
            </dd>
          </div>
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("invoice.table.customerAddress") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 max-w-[200px]"
            >
              {{ invoiceDetails.customerAddress || "-" }}
            </dd>
          </div>
          <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("invoice.table.vendorName") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 truncate"
            >
              {{ invoiceDetails.vendorName || "-" }}
            </dd>
          </div>
          <div
            v-if="invoiceDetails.datevJob"
            class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">DATEV</dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
            >
              <span
                :class="[
                  datevStatus(invoiceDetails.datevJob, $t).color,
                  datevStatus(invoiceDetails.datevJob, $t).textColor,
                ]"
                class="px-3 py-1 border border-gray-100 rounded"
                >{{ datevStatus(invoiceDetails.datevJob, $t).text }}</span
              >
              <p v-if="invoiceDetails.datevJob.completedAt" class="mt-2">
                {{ $t("purchaseOrder.datevStatuses.jobCompletedAt")
                }}{{
                  formatDateTime(invoiceDetails.datevJob.completedAt, language)
                }}
              </p>
              <div
                v-if="invoiceDetails.datevJob.warnings?.length"
                class="mt-2 border-l-4 border-l-hivebuy-red pl-2"
              >
                <p
                  v-for="warning in invoiceDetails.datevJob.warnings"
                  :key="warning"
                >
                  {{ warning }}
                </p>
              </div>
            </dd>
          </div>
          <div
            v-if="invoiceDetails.invoicePaid"
            class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("global.pricing.paidAt") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 max-w-[200px]"
            >
              {{
                formatDate(invoiceDetails.invoicePaidAt, true, language) || "-"
              }}
            </dd>
          </div>

          <div
            v-if="invoiceDetails.mergedInvoice?.length"
            class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
          >
            <dt class="text-sm font-medium leading-6 text-gray-900">
              {{ $t("global.auditTrail") }}
            </dt>
            <dd
              v-if="auditTrailFile"
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 truncate"
            >
              <div>
                <a
                  ref="downloadAuditTrail"
                  :href="auditTrailFile"
                  download="download"
                  target="_blank"
                  class="flex items-center gap-x-1 text-primary hover:underline"
                  >Download<ExternalLinkIcon class="h-4 w-4 text-primary"
                /></a>
              </div>
            </dd>
          </div>
        </dl>
        <div
          v-if="invoiceDetails.invoicePaid"
          class="mt-6 flex items-center content-center align-middle justify-center"
        >
          <span
            class="border-2 py-2 px-4 border-hivebuy-green border-dashed md:text-lg lg:text-xl rounded-md text-hivebuy-green font-semibold uppercase"
            >{{ $t("global.pricing.paid") }}</span
          >
        </div>
      </div>
    </div>
    <div
      class="md:flex grow overflow-y-auto max-h-[70vh]"
      :class="{ 'h-full ': isDetailedView }"
    >
      <InvoicePreview
        v-if="invoiceDetails.files"
        :invoice="invoiceDetails"
        :show-download="true"
        class="rounded-r-md"
      />
    </div>
  </div>
</template>

<script>
import { InvoicePreview } from "@/components/InvoiceApproval/index.js";
import CurrencyDisplay from "@/components/shared/CurrencyDisplay";
import StatusTag from "@/components/shared/StatusTag";
import { ExternalLinkIcon } from "@heroicons/vue/solid";
import { HivebuyLoader } from "@/components/shared/index";
import { datevStatus } from "@/utils/helpers/datevHelper.js";
import {
  currencyToLocale,
  formatDate,
  formatDateTime,
} from "@/utils/utility_methods.js";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapState } from "vuex";

export default {
  name: "InvoiceDetails",
  components: {
    InvoicePreview,
    StatusTag,
    CurrencyDisplay,
    ExternalLinkIcon,
    HivebuyLoader,
  },
  props: {
    invoiceDetails: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isDetailedView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    language() {
      return this.$i18n.locale;
    },
    auditTrailFile() {
      return this.invoiceDetails.mergedInvoice?.[0]?.file;
    },
  },
  methods: {
    currencyToLocale,
    datevStatus,
    formatDate,
    formatDateTime,
    currencyLocale(currency) {
      const updatedCurrency = currency || this.company.currency;
      return currencyToLocale(updatedCurrency);
    },
  },
};
</script>
