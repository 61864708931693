<template>
  <div class="w-full flex flex-col gap-y-4">
    <div>
      <label>{{ $t("global.file") }}</label>
      <UploadMedia
        :details="item"
        :show-file-options="false"
        :type="'file'"
        :on-file-upload="addFilesToObject"
        object-to-update="item"
        :show-paste-actions="false"
      />
    </div>
    <template v-if="isFeatureAllowed('customFields') && customFields.length">
      <div class="py-2 md:py-4 lg:py-6 border border-gray-300 rounded px-4">
        <CustomFieldForm
          v-model="item.customFields"
          :validation-object="v?.item?.customFields"
        />
      </div>
    </template>
    <RecurrenceInterval
      v-model="item.recurrence"
      :error="errorMessage('recurrence')"
    />
    <AdvanceOptionsForm
      v-if="!item.isIntegratedSupplierItem"
      v-model="item"
      :v="v"
      :show-address="false"
    />
  </div>
</template>

<script>
import UploadMedia from "@/components/PurchaseRequest/UploadMedia";
import RecurrenceInterval from "@/components/shared/RecurrenceInput.vue";
import { addFilesImagesToObjectMixin } from "@/components/mixins/index.js";
import AdvanceOptionsForm from "@/components/PurchaseRequest/Form/AdvanceOptionsForm.vue";
import CustomFieldForm from "@/components/CustomFields/CustomFieldForm.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapState } from "vuex";
import newRequestErrorMixin from "@/components/mixins/newRequestErrorMixin.js";
import { CATALOGUE_ITEM_SOURCE } from "@/utils/constants";

export default {
  name: "AdvanceInfo",
  components: {
    UploadMedia,
    AdvanceOptionsForm,
    CustomFieldForm,
    RecurrenceInterval,
  },
  mixins: [addFilesImagesToObjectMixin, newRequestErrorMixin],
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    v: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["customFields"]),
    item: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    isCatalogueItem() {
      return (
        this.requestedForType(this.item.sourceType) ===
        CATALOGUE_ITEM_SOURCE.CATALOGUE_ITEM
      );
    },
    isIntegratedSupplier() {
      return (
        this.requestedForType(this.item.sourceType) ===
        CATALOGUE_ITEM_SOURCE.SUPPLIER
      );
    },
  },
  methods: {
    saveRequestAttributes(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
