<template>
  <HivebuyLoader v-if="loading" class="h-8 w-8" />
  <div v-else>
    <Multiselect
      v-model="companySupplier"
      :class="['w-full bg-white', { 'border-red-400': !!errorFieldText }]"
      :track-by="object ? undefined : trackBy"
      :searchable="true"
      :value-prop="valueProp"
      name="supplier"
      :options="filteredCompanySuppliers"
      :placeholder="placeholder"
      :mode="mode"
      :label="label"
      :hide-selected="hideSelected"
      :object="object"
      :can-clear="canClear"
      :filter-results="!shouldExecutePaginationApi"
      :loading="isApiLoading('companySuppliers')"
      :show-options="!isApiLoading('companySuppliers')"
      :disabled="disabled"
      :clear-on-search="clearOnSearch"
      :create-option="createOption"
      @search-change="setSearch"
      @change="onSupplierChange"
    >
      <template #afterlist>
        <div
          v-if="afterListPlaceholder"
          class="p-2 text-sm font-light text-gray-400"
        >
          {{ afterListPlaceholder }}
        </div>
      </template>
      <template #option="{ option }">
        <div class="flex items-center">
          <span>
            <LightningBoltIcon v-if="option.supplier" class="h-3 w-3" />
            <GlobeAltIcon v-else class="h-3 w-3" />
          </span>
          <p class="font-medium ml-1">
            {{ option.name }}
          </p>
        </div>
      </template>
    </Multiselect>
    <FieldError v-if="errorFieldText" :text="errorFieldText" />
  </div>
</template>

<script>
import { FieldError, HivebuyLoader } from "@/components/shared/index";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  FETCH_COMPANY_SUPPLIER,
} from "@/store/CompanyManagement/types";
import Multiselect from "@vueform/multiselect";
import { GlobeAltIcon, LightningBoltIcon } from "@heroicons/vue/outline";
import suppliersMixin from "@/components/mixins/suppliersMixin";
import { isObjectEmpty, isString } from "@/utils/utility_methods";

export default {
  name: "SupplierSelect",
  components: {
    FieldError,
    LightningBoltIcon,
    GlobeAltIcon,
    Multiselect,
    HivebuyLoader,
  },
  mixins: [suppliersMixin],
  props: {
    modelValue: {
      type: [String, Array, Object, null],
      default: null,
    },
    mode: {
      type: String,
      default: "single",
    },
    label: {
      type: String,
      default: "label",
    },
    trackBy: {
      type: String,
      default: "name",
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    object: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select Department",
    },
    canClear: {
      type: Boolean,
      default: true,
    },
    categoryToFilter: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorFieldText: {
      type: String,
      default: "",
    },
    afterListPlaceholder: {
      type: String,
      default: "",
    },
    clearOnSearch: {
      type: Boolean,
      default: false,
    },
    createOption: {
      type: Boolean,
      default: false,
    },
    valueProp: {
      type: String,
      default: "id",
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      loading: false,
      optionsList: [],
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, {
      companySuppliersList: "companySuppliersList",
      getSupplierBySearch: "getSupplierBySearch",
      companySupplierGetter: "getCompanySupplier",
    }),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["companySuppliers"]),
    companySupplier: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    filteredCompanySuppliers() {
      const search = this.search.toLowerCase();
      return this.getSupplierBySearch(
        this.optionsList,
        search,
        !!this.categoryToFilter
      );
    },
  },
  watch: {
    modelValue: {
      async handler(value) {
        await this.updateOptionsList(value);
      },
      immediate: true,
      deep: true,
    },
    companySuppliers: {
      handler() {
        this.optionsList = this.companySuppliersList();
      },
      deep: true,
    },
    categoryToFilter: {
      async handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.companySupplier = null;
          this.optionsList = await this.getSuppliersByCategory(newVal, true);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCompanySupplier: FETCH_COMPANY_SUPPLIER,
    }),
    onSupplierChange(value, { name }) {
      this.$emit("change", value, { name });
    },
    setSearch(query) {
      this.executeDebounceSearch(this.onSearchUpdate, {
        key: "search",
        value: query.toLowerCase(),
      });
    },
    async updateOptionsList(modelValue) {
      if (this.isUnlistedSupplier(modelValue) || this.loading) return;
      this.loading = true;
      const value = Array.isArray(modelValue)
        ? modelValue.slice()
        : [modelValue?.id || modelValue].filter((v) => !isObjectEmpty(v));

      if (value.length) {
        for (const supplier of value) {
          await this.getCompanySupplier(supplier?.id || supplier);
        }
      }
      this.loading = false;
    },
    isUnlistedSupplier(value) {
      if (!isString(value) || !value) return false;
      return !this.companySupplierGetter(value);
    },
  },
};
</script>
