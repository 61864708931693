<template>
  <div v-if="isUserOnlyAuditor" class="text-sm m-1 text-gray-500">
    *Auditor can't add items to cart
  </div>
  <div v-else-if="isItemValid">
    <Button
      full
      :class="['w-auto', buttonProps.class]"
      :on-click-handler="
        () =>
          newRequestItem(0)?.isIntegratedSupplierItem
            ? toggleConfirmModal()
            : addItemToCart(item)
      "
      :disabled="buttonProps.disabled"
      :loading="addedToCartLoading"
    >
      <component :is="buttonProps.icon" class="mr-1 w-5 h-5" />
      {{ buttonProps.text }}
    </Button>
  </div>
  <div v-else class="w-auto">
    <div
      class="text-gray-400 border border-gray-300 bg-gray-50 font-normal py-2.5 px-4 rounded flex justify-center items-center"
    >
      {{ $t("catalogue.catalogueItems.invalidItem") }}
      <XIcon class="mr-1 w-4 h-4 text-gray-400" />
    </div>
  </div>
  <ConfirmDialog
    :text="$t('catalogue.resetAmazon')"
    :show-modal="confirmModal"
    :close-modal="toggleConfirmModal"
    title="Reset Amazon Request"
    :confirm-modal="resetRequest"
  />
</template>

<script>
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import {
  PlusCircleIcon,
  XIcon,
  ShoppingCartIcon,
} from "@heroicons/vue/outline";
import { Button } from "@/components/shared";
import {
  newRequestMixin,
  catalogueItemMixin,
} from "@/components/mixins/index.js";
export default {
  name: "ItemCartButton",
  components: {
    PlusCircleIcon,
    XIcon,
    Button,
    ConfirmDialog,
    ShoppingCartIcon,
  },
  mixins: [newRequestMixin, catalogueItemMixin],
  props: {
    item: {
      type: Object,
      required: true,
      ConfirmDialog,
    },
    addedToCartLoading: {
      type: Boolean,
      required: true,
    },
    addItemToCart: {
      type: Function,
      required: true,
    },
    isListView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmModal: false,
    };
  },
  computed: {
    buttonProps() {
      if (this.isItemAddedInCart(this.item)) {
        return {
          class: "add-item-again",
          icon: PlusCircleIcon,
          text: this.$t("catalogue.catalogueItems.addItemAgain"),
          disabled: !this.isFeatureAllowed("itemLevelApproval"),
        };
      }
      return {
        class: "add-to-cart ",
        icon: ShoppingCartIcon,
        text: this.$t("catalogue.catalogueItems.addToCart"),
        disabled: !this.allowSameCategoryAndSupplier(this.item),
      };
    },
    isItemValid() {
      return this.isValidCatalogueItem(this.item);
    },
  },
  methods: {
    toggleConfirmModal() {
      this.confirmModal = !this.confirmModal;
    },
    resetRequest() {
      this.saveRequestAttributes({
        action: "reset",
      });
      this.addItemToCart(this.item);
      this.toggleConfirmModal();
    },
    isItemAddedInCart(catalogItem) {
      const item = this.newRequest.items.find(
        (item) => item.itemId === catalogItem.id
      );
      return !!item;
    },
  },
};
</script>

<style scoped>
.add-to-cart {
  @apply text-base font-semibold bg-primary text-white w-full border-0 py-3 hover:bg-primarydark;
}

.add-item-again {
  @apply text-base font-semibold bg-white text-primary w-full py-3 hover:bg-white hover:text-primarydark border-primary;
}
</style>
