<template>
  <Panel
    :id="index"
    class="panel-header"
    toggleable
    @update:collapsed="collapsePanel"
  >
    <template #togglericon>
      <div class="">
        <ChevronDownIcon
          class="h-6 w-6 transition-transform"
          :class="panelCollapsed ? '' : 'rotate-180'"
        />
      </div>
    </template>
    <template #header>
      <div v-auto-animate class="w-full">
        <div
          v-if="deleteConfirmation"
          class="md:flex md:items-center md:justify-between text-red-500 w-full text-base font-medium"
        >
          <div class="min-w-0 flex-1">
            {{ $t("purchaseRequest.summary.deleteConfirmDialog.text") }}
          </div>

          <div class="mt-4 flex md:ml-4 md:mt-0 mr-2 md:mr-4">
            <button
              type="button"
              class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              @click="deleteConfirmationToggle"
            >
              {{ $t("buttons.cancel") }}
            </button>
            <button
              type="button"
              class="ml-3 inline-flex items-center rounded-md !bg-hivebuy-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:ring-1 hover:ring-offset-2 hover:ring-hivebuy-red focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              @click="
                () => {
                  saveRequestAttributes({ index, action: 'delete' });
                  deleteConfirmationToggle();
                }
              "
            >
              {{ $t("buttons.delete") }}
            </button>
          </div>
        </div>
        <div v-else>
          <div
            class="min-w-0"
            :class="
              panelCollapsed
                ? 'grid grid-cols-4 gap-x-2'
                : 'flex justify-between gap-x-2'
            "
          >
            <div
              :class="[{ 'border-hivebuy-red rounded': showErrorBoundary }]"
              class="text-primary font-semibold col-span-2 line-clamp-1 flex items-center gap-x-2"
            >
              <ItemSourceTag :list="[item.sourceType]" />
              {{ item.name || "-" }}
            </div>
            <div
              v-if="panelCollapsed"
              class="text-base font-medium flex items-center gap-x-1"
            >
              <i18n-n
                v-if="item.netAmount"
                :value="parseFloat(item.netAmount)"
                format="currency"
                :locale="currencyToLocale(item.netAmountCurrency)"
              />
              <div
                v-if="item.quantity > 1"
                class="flex items-center text-sm font-normal"
              >
                (x{{ item.quantity }})
              </div>
            </div>
            <div
              class="flex items-center gap-x-1 rounded-full py-0.5 px-1 border border-transparent hover:border-gray-200 hover:bg-gray-200 mr-2 md:mr-6 justify-center"
              :class="panelCollapsed ? 'justify-self-end ' : ''"
            >
              <TrashIcon
                class="w-5 h-5 text-hivebuy-red/60 cursor-pointer hover:text-hivebuy-red"
                aria-hidden="true"
                @click="deleteConfirmationToggle"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <div
      :class="[
        'pb-4 px-2 flex flex-col',
        {
          'rounded ring-1 ring-offset-2 ring-hivebuy-red': showErrorBoundary,
        },
      ]"
    >
      <div id="tab-section" class="hcontainer pt-1 pb-2 mb-2">
        <div class="htabs">
          <input
            id="option1"
            type="radio"
            name="htabs"
            :checked="currentTab == 'basic'"
          />
          <label
            class="htab flex items-center gap-x-2"
            :class="isBasicChecked ? 'checked' : ''"
            for="option1"
            @click="changeTab('basic')"
          >
            {{ $t("purchaseRequest.newRequest.basicInformation") }}
            <span v-if="basicFieldErrors" class="h-4 w-4"
              ><ExclamationIcon
            /></span>
          </label>
          <input
            id="option2"
            type="radio"
            name="htabs"
            :checked="currentTab == 'advance'"
          />
          <label
            class="htab flex items-center gap-x-2"
            :class="isBasicChecked ? '' : 'checked'"
            for="option2"
            @click="changeTab('advance')"
            >{{ $t("purchaseRequest.newRequest.advancedInformation") }}
            <span v-if="advanceFieldErrors" class="h-4 w-4"
              ><ExclamationIcon
            /></span>
          </label>
          <span
            class="hglider"
            :class="isBasicChecked ? 'hglider-basic' : 'hglider-advance'"
          ></span>
        </div>
      </div>
      <div ref="viewportElement" v-auto-animate class="flex w-full bg-white">
        <ItemBasicInfo v-if="currentTab == 'basic'" v-model="item" :v="v$" />
        <AdvanceInfo v-if="currentTab == 'advance'" v-model="item" :v="v$" />
      </div>
    </div>
  </Panel>
</template>

<script>
import {
  ItemBasicInfo,
  AdvanceInfo,
} from "@/components/RequestCreation/index.js";
import { newRequestMixin } from "@/components/mixins/index.js";
import Panel from "primevue/panel";
import {
  TrashIcon,
  ExclamationIcon,
  ChevronDownIcon,
} from "@heroicons/vue/solid";
import useValidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";
import { newRequestedForValidation } from "@/utils/helpers/purchaseRequestHelper";
import { currencyToLocale, deepClone } from "@/utils/utility_methods.js";
import { greaterThanZero } from "@/utils/helpers/purchaseRequestHelper";
import { COST_RECURRENCE_TYPES } from "@/utils/constants.js";
import ItemSourceTag from "@/components/RequestCreation/ItemSourceTag.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ItemForm",
  components: {
    Panel,
    ItemBasicInfo,
    TrashIcon,
    AdvanceInfo,
    ItemSourceTag,
    ExclamationIcon,
    ChevronDownIcon,
  },
  mixins: [newRequestMixin],
  provide() {
    return {
      index: this.index,
    };
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    setValidationObject: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      currentTab: "basic",
      deleteConfirmation: false,
      panelCollapsed: false,
      v$: useValidate(),
      selectedTab: "1",
    };
  },

  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["customFields"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getCustomFieldsBySource"]),
    showErrorBoundary() {
      if (this.isFeatureAllowed("itemLevelApproval")) return this.v$.$error;
      const nonItemFields = ["category", "companySupplier"];
      const errors = this.v$.$errors.map((error) => error.$property);
      return !!errors.filter((item) => !nonItemFields.includes(item)).length;
    },
    item: {
      get() {
        return this.newRequestItem(this.index) || { price: {} };
      },
      set(value) {
        this.saveRequestAttributes({ item: value, index: this.index });
      },
    },
    isBasicChecked() {
      return this.currentTab === "basic";
    },
    isAdvancedChecked() {
      return this.currentTab === "advance";
    },
    basicFieldErrors() {
      const fields = [
        "name",
        "category",
        "unit",
        "quantity",
        "netAmount",
        "netGross",
        "netAmountCurrency",
      ];
      const errors = this.v$.$errors.map((error) => error.$property);
      return fields.some((field) => errors.includes(field));
    },
    advanceFieldErrors() {
      return this.v$.$error && !this.basicFieldErrors;
    },
    customFieldSource() {
      return this.item?.isCatalogueItem
        ? "catalogueUserInput"
        : this.item?.isIntegratedSupplierItem
        ? "integrated"
        : "freeForm";
    },
  },

  watch: {
    item: {
      handler(newVal, oldVal) {
        const compareKeys = ["isCatalogueItem", "isIntegratedSupplierItem"];
        if (
          oldVal &&
          compareKeys.every((key) => newVal?.[key] == oldVal?.[key])
        )
          return false;
        this.item.customFields = this.itemCustomFields();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (!this.isFeatureAllowed("itemLevelApproval") && !this.index) {
      this.setValidationObject(this.v$.item);
    }
  },
  methods: {
    currencyToLocale,

    async validateRequestData() {
      const response = await this.v$.$validate();
      if (!response && this.advanceFieldErrors) {
        this.currentTab = "advance";
      }
      return response;
    },
    deleteConfirmationToggle() {
      this.deleteConfirmation = !this.deleteConfirmation;
    },
    collapsePanel(panelCollapsed) {
      this.panelCollapsed = panelCollapsed;
    },
    toggleTabs() {
      if (this.currentTab == "advance") {
        this.currentTab = "basic";
        return;
      }
      if (this.currentTab == "basic") {
        this.currentTab = "advance";
        return;
      }
    },
    itemCustomFields() {
      const sourceCustomFields = [
        ...deepClone(this.getCustomFieldsBySource(this.customFieldSource)),
      ];

      this.item.customFields?.forEach((field) => {
        const itemFieldIndex = sourceCustomFields.findIndex(
          (itemField) => itemField.name === field.name
        );

        if (itemFieldIndex > -1) {
          sourceCustomFields[itemFieldIndex].value = field.value;
        } else if (this.item.isCatalogueItem) {
          const newField = Object.assign({}, field, {
            readOnly: true,
            id: this.customFields.find((cf) => cf.name === field.name)?.id,
          });
          sourceCustomFields.push(newField);
        }
      });
      return sourceCustomFields.map((field) => {
        field.customField = field.id;
        delete field.id;
        return field;
      });
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
  },
  validations() {
    return {
      item: {
        name: { required },
        category: { required },
        companySupplier: {
          required: requiredIf(() => {
            if (this.item.companySupplier) return false;
            if (this.item.unlistedSupplier) return false;
            if (this.item.skipSupplier) return false;
            return true;
          }),
        },
        unit: { required },
        quantity: {
          required,
          minValue: greaterThanZero,
        },
        netAmount: { required, minValue: greaterThanZero },
        netGross: { required },
        netAmountCurrency: { required },
        paymentMethod: {
          required: requiredIf(() => !this.item.isIntegratedSupplierItem),
        },
        invoiceAddress: { required },
        companyAddress: { required },
        requestedForId: { required },
        requestedForType: { required },
        recurrence: {
          validTo: {
            required: requiredIf(() =>
              [
                COST_RECURRENCE_TYPES.monthly,
                COST_RECURRENCE_TYPES.yearly,
              ].includes(this.item.recurrence.interval)
            ),
          },
          servicePeriodStart: {
            required: requiredIf(
              () =>
                this.item.recurrence.interval ==
                COST_RECURRENCE_TYPES.servicePeriod
            ),
          },
          servicePeriodEnd: {
            required: requiredIf(
              () =>
                this.item.recurrence.interval ==
                COST_RECURRENCE_TYPES.servicePeriod
            ),
          },
        },
        department: {
          required: requiredIf(() =>
            newRequestedForValidation(this.item, "department")
          ),
        },
        project: {
          required: requiredIf(() =>
            newRequestedForValidation(this.item, "project")
          ),
        },
        customFields: {
          $each: helpers.forEach({
            value: {
              required: requiredIf(
                (_value, object) =>
                  this.isFeatureAllowed("customFields") &&
                  object.mandatoryField &&
                  !object.readOnly
              ),
            },
          }),
        },
      },
    };
  },
};
</script>

<style>
.navigation-icon {
  @apply w-8 h-8 hover:text-primary cursor-pointer;
}

:deep(.p-panel-header) {
  @apply bg-gray-50 !important;
}

.panel-header {
  scroll-margin: 140px;
}

/* SLIDER */

*,
*:after,
*:before {
  box-sizing: border-box;
}

.hcontainer {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.htabs {
  @apply text-sm rounded border border-gray-300 p-1;
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(#9e4770, 0.15), 0 6px 12px 0 rgba(#9e4770, 0.15);
}
.htabs > * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
}

.htab {
  @apply text-sm font-medium rounded text-gray-400 hover:bg-gray-100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 200px;
  cursor: pointer;
  transition: color 0.15s ease-in;
}

.checked {
  @apply text-white hover:bg-transparent;
}

.hglider {
  @apply bg-primary rounded;
  position: absolute;
  display: flex;
  height: 24px;
  width: 200px;
  z-index: 1;
  transition: 0.25s ease-out;
}

.hglider-basic {
  transform: translateX(0%);
}

.hglider-advance {
  transform: translateX(100%);
}

@media (max-width: 700px) {
  .htabs {
    transform: scale(0.6);
  }
}
</style>
