<template>
  <div class="catalogue">
    <div class="flex flex-col sm:flex-row justify-between mb-2">
      <h2 class="page-title">
        {{ $t("home.catalogue") }}
      </h2>
      <Button
        v-if="isValidItemInCart"
        :on-click-handler="goToCheckout"
        show-icon
        class="fixed top-20 left-1/2 z-10 shadow-md shadow-gray-500"
      >
        <template #icon><ShoppingCartIcon /></template>
        {{ $t("buttons.checkout") }}
      </Button>
      <Button
        :on-click-handler="toggleButtonProps.handler"
        show-icon
        color="success"
      >
        <template #icon>
          <component :is="toggleButtonProps.icon" />
        </template>
        {{ toggleButtonProps.text }}
      </Button>
    </div>

    <div class="custom-card overflow-hidden">
      <div v-auto-animate class="content-holder">
        <component :is="currentComponent" />
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "@/components/shared";
import List from "@/components/Catalogue/CatalogueItems/List";
import AdminList from "@/components/Catalogue/Admin/List";

import { mapState, mapGetters } from "vuex";
import { CATALOGUE_MODULE } from "@/store/Catalogue/types";
import { companySuppliers } from "@/components/mixins/index.js";
import { UserIcon, ShoppingCartIcon } from "@heroicons/vue/outline";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
export default {
  components: {
    List,
    AdminList,
    Button,
    UserIcon,
    ShoppingCartIcon,
  },
  mixins: [companySuppliers],
  data() {
    return {
      currentComponent: "List",
    };
  },
  computed: {
    ...mapState(CATALOGUE_MODULE, {
      catalogues: (state) => state.catalogues,
    }),
    ...mapGetters(PURCHASE_REQUEST_MODULE, [
      "newRequestItem",
      "isValidItemInCart",
    ]),
    toggleButtonProps() {
      return {
        List: {
          text: "Admin Section",
          icon: UserIcon,
          handler: () => {
            this.currentComponent = "AdminList";
          },
        },
        AdminList: {
          text: "Catalogue Items",
          icon: ShoppingCartIcon,
          handler: () => {
            this.currentComponent = "List";
          },
        },
      }[this.currentComponent];
    },
  },
  methods: {
    goToCheckout() {
      this.$router.push({ name: "CreatePurchaseRequest" });
    },
  },
};
</script>
