export const PR_BASIC_KEYS = [
  "description",
  "category",
  "supplier",
  "title",
  "status",
];
export const PR_CREATE_KEYS = [...PR_BASIC_KEYS, "status", "items"];
export const ITEM_BASIC_KEYS = [
  "category",
  "companyAddress",
  "companySupplier",
  "description",
  "files",
  "images",
  "invoiceAddress",
  "itemId",
  "link",
  "name",
  "shortName",
  "paymentMethod",
  "requestedFor",
  "requestedForId",
  "requestedForType",
  "department",
  "project",
  "source",
  "shoppingItems",
  "unlistedSupplier",
  "unlistedSupplierReason",
  "updated",
  "article",
  "recurrence",
  "customFields",
];

export const ITEM_CREATE_KEYS = [
  ...ITEM_BASIC_KEYS,
  "department",
  "grossAmount",
  "grossAmountCurrency",
  "needByDate",
  "netAmount",
  "netAmountCurrency",
  "netGross",
  "position",
  "quantity",
  "recurrence",
  "shipping",
  "unit",
  "vat",
  "estimatedDelivery",
  "catalogueItemVariant",
];

export const VALID_PR_FIELDS = ["title", "items"];
export const VALID_ITEM_FIELDS = [
  "name",
  "quantity",
  "unit",
  "netAmount",
  { requestedFor: ["type", "id"] },
];
