<template>
  <div>
    <h3 class="text-sm font-medium leading-6 text-primary text-center mb-0.5">
      {{ $t("global.requestedFor") }}
    </h3>
    <InlineEdit
      class="w-full"
      :disabled="disabled"
      :dismissable="isValuePresent"
      :show-close-icon="isValuePresent"
    >
      <template #header>
        <div class="header">
          {{ $t("purchaseRequest.requestedFor.whoWillUseItHeader") }}
        </div>
      </template>
      <template #selected>
        <div class="w-full group relative truncate-min">
          <DisplayRequestedFor :requested-for="requestedFor" />
          <FieldError v-if="error" :text="error" />
        </div>
      </template>
      <template #content>
        <div class="px-2 py-4">
          <SelectRequestedFor
            v-model="requestedFor"
            is-mandatory
            :show-header="false"
            :show-info-text="true"
          />
        </div>
        <FieldError
          v-if="!isValuePresent"
          :text="$t('errors.required', { entity: 'requestedFor' })"
        />
      </template>
      <template #footer>
        {{ $t("purchaseRequest.detail.requestedFor") }}
      </template>
    </InlineEdit>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import {
  SelectRequestedFor,
  DisplayRequestedFor,
  FieldError,
} from "@/components/shared";
import { InlineEdit } from "design-buy";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  name: "RequestedFor",
  components: {
    DisplayRequestedFor,
    SelectRequestedFor,
    InlineEdit,
    FieldError,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["newRequestItem"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companySetting"]),
    requestedFor: {
      get() {
        return {
          id: this.modelValue.requestedForId,
          type: this.modelValue.requestedForType,
          user: this.modelValue.user,
          department: this.modelValue.department,
          project: this.modelValue.project,
        };
      },
      set(val) {
        const payload = {
          requestedForId: val.id,
          requestedForType: val.type,
          user: val.user,
          department: val.department,
          project: val.project,
        };
        this.$emit("update:modelValue", payload);
      },
    },
    isValuePresent() {
      const { type, id } = this.requestedFor;
      return !!(type && id);
    },
    shouldSelectDepartmentAsRequestedFor() {
      const { roles, departments } = this.user;
      return (
        this.companySetting("ONLY_DEPARTMENTS_IN_REQUESTED_FOR")?.active &&
        (!roles.length || (roles.length === 1 && roles.includes("AP"))) &&
        departments.length
      );
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newVal) {
        if (!newVal?.requestedForId) {
          const { id, departments } = this.user;
          this.requestedFor = Object.assign(
            {},
            {
              id: this.shouldSelectDepartmentAsRequestedFor
                ? departments[0].id
                : id,
              department: departments[0]?.id,
              type: this.shouldSelectDepartmentAsRequestedFor
                ? "department"
                : "user",
            }
          );
        }
      },
    },
  },
};
</script>
