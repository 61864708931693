<template>
  <div class="catalogue-header">
    <div class="flex gap-x-4">
      <div class="label flex-1 mr-6">
        {{ $t("invoice.dashboard.searchBy") }}
      </div>
      <div
        v-if="isFeatureAllowed('catalogues')"
        class="flex-1 flex justify-between"
      >
        <p class="label">{{ $t("catalogue.tabs.catalogue") }}</p>
        <div v-if="showCatalogueActions" class="inline-flex">
          <PencilAltIcon
            class="w-5 h-5 text-gray-300 mr-2 cursor-pointer hover:text-primary"
            aria-hidden="true"
            @click="onCatalogueEdit"
          />
          <TrashIcon
            class="w-5 h-5 text-gray-300 mr-2 cursor-pointer hover:text-hivebuy-red"
            aria-hidden="true"
            @click="onCatalogueDelete"
          />
        </div>
      </div>
    </div>
    <div class="dropdown-search">
      <div class="search">
        <SearchInput
          :value="filters.paginationParams.search"
          :on-change="onSearchInput"
          :placeholder="$t('general.search')"
          :loading="loading"
        />
      </div>

      <Multiselect
        v-if="isFeatureAllowed('catalogues')"
        v-model="selectedCatalogue"
        :searchable="true"
        :options="catalogueOptions"
        :placeholder="$t('catalogue.tabs.selectACat')"
        :value-prop="'id'"
        :label="'name'"
        :track-by="'name'"
        :can-deselect="false"
        :loading="loading"
        class="flex-1 bg-white"
      />

      <div
        v-if="
          isFeatureAllowed('customFields') &&
          !isProductionEnvironment() &&
          !isApiLoading()
        "
      >
        <CustomFieldFilters
          :update-filters="updateCustomFieldFilters"
          :advanced-filters="filters.advancedFilters"
        />
      </div>
    </div>
    <CatalogueForm
      :show-modal="showEditCatalogueModal"
      :close-modal="onEditCatalogueModalClose"
      :catalogue-to-edit="catalogueToEdit"
    />
    <ConfirmDialog
      text="Are you sure you want to delete the following catalogue?"
      :show-modal="showDeleteCatalogueModal"
      :close-modal="onDeleteModalClose"
      title="Delete Catalogue"
      :item-to-confirm="catalogueToDelete?.name"
      :confirm-modal="onDeleteCatalogue"
    />
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import SearchInput from "@/components/PurchaseRequest/PurchaseRequestList/SearchInput";
import { isProductionEnvironment } from "@/utils/utility_methods";
import { mapActions, mapState } from "vuex";
import {
  DELETE_CATALOGUE,
  GET_CATALOGUE_ITEMS,
  CATALOGUE_MODULE,
  GET_CATALOGUES,
} from "@/store/Catalogue/types";
import { ConfirmDialog } from "@/components/shared";
import { PencilAltIcon, TrashIcon } from "@heroicons/vue/solid";
import CustomFieldFilters from "@/components/Catalogue/CustomFieldFilters";
import CatalogueForm from "@/components/Catalogue/CatalogueForm";
export default {
  name: "Filters",
  components: {
    Multiselect,
    SearchInput,
    ConfirmDialog,
    PencilAltIcon,
    TrashIcon,
    CustomFieldFilters,
    CatalogueForm,
  },
  props: {
    onSearchInput: {
      type: Function,
      required: true,
    },
    updateFilters: {
      type: Function,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    showActions: {
      type: Boolean,
      required: true,
    },
    updateCustomFieldFilters: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showEditCatalogueModal: false,
      catalogueToEdit: {},
      showDeleteCatalogueModal: false,
      catalogueToDelete: {},
    };
  },
  computed: {
    ...mapState(CATALOGUE_MODULE, ["catalogues"]),
    selectedCatalogue: {
      get() {
        return this.filters.paginationParams.catalogues;
      },
      set(val) {
        const updatedValue = val || "";
        this.updateFilters("catalogues", updatedValue, "paginationParams");
      },
    },
    catalogueOptions() {
      const catalogues = this.catalogues.map((catalogue) => {
        return { name: catalogue.name, id: catalogue.id };
      });

      return [{ name: this.$t("global.all"), id: "" }, ...catalogues];
    },
    showCatalogueActions() {
      return !!(this.selectedCatalogue && this.showActions);
    },

    loading() {
      return this.isApiLoading("catalogueItemsLoading");
    },
  },
  created() {
    !this.catalogues.length && this.fetchCatalogues();
  },
  mounted() {
    const { catalogueId } = this.$route.query;
    if (!catalogueId || this.selectedCatalogue == catalogueId) return;

    this.selectedCatalogue = catalogueId;
  },
  methods: {
    ...mapActions(CATALOGUE_MODULE, {
      getCatalogueItems: GET_CATALOGUE_ITEMS,
      deleteCatalogue: DELETE_CATALOGUE,
      getCatalogues: GET_CATALOGUES,
    }),
    isProductionEnvironment,
    fetchCatalogues() {
      this.getCatalogues().catch((error) => this.showErrorMessage(error));
    },
    onEditCatalogueModalClose() {
      this.showEditCatalogueModal = false;
      this.catalogueToEdit = {};
    },
    onCatalogueEdit() {
      this.catalogueToEdit = this.catalogues.find(
        (catalog) => catalog.id === this.selectedCatalogue
      );
      this.showEditCatalogueModal = true;
    },
    onDeleteModalClose() {
      this.catalogueToDelete = {};
      this.showDeleteCatalogueModal = false;
    },
    onCatalogueDelete() {
      this.catalogueToDelete = this.catalogues.find(
        (catalog) => catalog.id === this.selectedCatalogue
      );
      this.showDeleteCatalogueModal = true;
    },
    onDeleteCatalogue() {
      this.selectedCatalogue = "";
      this.deleteCatalogue(this.catalogueToDelete.id)
        .then(() => {
          this.onDeleteModalClose();
          this.showNotification(this.$t("toasts.genericSuccess"));
        })
        .catch((error) => this.showErrorMessage(error));
    },
  },
};
</script>

<style scoped>
.catalogue-header {
  @apply bg-gray-50 -mx-5 -mt-5 p-5 mb-4 border-b border-gray-200;
}
.dropdown-search {
  @apply flex gap-x-4;
}

.search {
  @apply border border-gray-200 rounded pl-4 flex-1 bg-white;
}
</style>
