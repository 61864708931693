import { mapMutations, mapState, mapGetters } from "vuex";

import { CATALOGUE_ITEM_SOURCE } from "@/utils/constants";
import {
  PURCHASE_REQUEST_MODULE,
  SAVE_NEW_REQUEST_ATTRIBUTES,
} from "@/store/PurchaseRequest/types";
import { AUTH_MODULE } from "@/store/Auth/types";
import { isObjectEmpty, deepEqual } from "@/utils/utility_methods";

export default {
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["newRequest"]),
    ...mapGetters(AUTH_MODULE, ["modelId"]),
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["newRequestItem"]),
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      saveRequestAttributes: SAVE_NEW_REQUEST_ATTRIBUTES,
    }),
    isSameRequestedFor(item, requestedFor) {
      const itemRequestedFor = {
        id: item.requestedForId,
        type: item.requestedForType,
        department: item.department,
        project: item.project,
      };

      return deepEqual(itemRequestedFor, requestedFor);
    },
    itemQuantity(item, requestedFor, index) {
      let quantity = 1;
      const existingItem = this.newRequestItem(index);

      if (index > -1 && this.isSameRequestedFor(existingItem, requestedFor)) {
        quantity =
          existingItem.quantity + parseInt(item.quantityStep || item.quantity);
      } else {
        quantity = parseInt(
          item.quantity ? item.quantity : item.minimalQuantity
        );
      }

      return quantity;
    },
    getRequestedForObj(requestedFor) {
      if (requestedFor) return requestedFor;
      if (!this.newRequest.items[0]) {
        throw new Error("RequestedFor info is missing");
      }
      const { requestedForId, requestedForType, department, project } =
        this.newRequest.items[0];
      return {
        id: requestedForId,
        type: requestedForType,
        department: department,
        project: project,
      };
    },
    saveToStore(catalogItem, requestedForObj = null) {
      const requestedFor = this.getRequestedForObj(requestedForObj);
      const index = this.newRequest.items.findIndex(
        (item) => item.catalogueItemVariant === catalogItem.catalogueItemVariant
      );

      const payload = {
        item: this.itemParams(catalogItem, requestedFor, index),
      };
      if (
        index > -1 &&
        this.isSameRequestedFor(this.newRequestItem(index), requestedFor)
      ) {
        payload.index = index;
      }
      if (!this.newRequestItem(0)?.name && !this.newRequestItem(0)?.netAmount) {
        payload.index = 0;
      }

      this.saveRequestAttributes(payload);
    },

    itemSource(item) {
      return {
        sourceType: this.modelId(CATALOGUE_ITEM_SOURCE.CATALOGUE_ITEM),
        sourceId: item.id,
      };
    },
    itemParams(item, requestedFor, index = -1) {
      const quantity = this.itemQuantity(item, requestedFor, index);
      const {
        id: requestedForId,
        type: requestedForType,
        department,
        project,
      } = requestedFor;
      const recurrence = isObjectEmpty(item.recurrence) ? {} : item.recurrence;
      const companySupplier = item.companySupplier;

      const { sourceType, sourceId } = this.itemSource(item);
      const defaultValues = {};
      if (
        !this.isFeatureAllowed("itemLevelApproval") &&
        this.newRequestItem(0)
      ) {
        const copyFields = [
          "companyAddress",
          "invoiceAddress",
          "paymentMethod",
        ];
        copyFields.forEach((field) => {
          defaultValues[field] = this.newRequestItem(0)[field];
        });
      }

      return {
        ...defaultValues,
        requestedForId,
        requestedForType,
        department,
        project,
        quantity,
        recurrence,
        companySupplier,
        sourceType,
        sourceId,
        article: item.article,
        category: item.category,
        catalogueItemVariant: item.catalogueItemVariant,
        catalogues: item.catalogues,
        grossAmount: item.grossAmount,
        grossAmountCurrency: item.grossAmountCurrency,
        netAmount: item.netAmount,
        netAmountCurrency: item.netAmountCurrency,
        netGross: "net",
        itemId: item.id,
        images: item.images,
        files: item.files,
        minimalQuantity: item.minimalQuantity,
        name: item.name,
        order: item.order,
        quantityStep: item.quantityStep,
        shortName: item.shortName,
        tags: item.tags,
        unlistedSupplier: item.unlistedSupplier,
        link: item.url,
        vat: item.vat,
        tax: item.grossAmount - item.netAmount,
        unit: "pc",
        isCatalogueItem: true,
        customFields: item.customFields,
      };
    },
  },
};
