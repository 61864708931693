<template>
  <Form />
</template>

<script>
import { Form } from "@/components/RequestCreation";
export default {
  name: "RequestCreation",
  components: {
    Form,
  },
};
</script>
